import React from "react";
import { StyleSheet, css } from "aphrodite";
import { CpButton } from "canopy-styleguide!sofe";

export default function Footer({ activeStep, isValid, sending, back, next }) {
  const sendInvitationStep = 2;

  return (
    <div className={`${css(styles.footer)} ${activeStep !== 0 ? css(styles.withBackButton) : ""}`}>
      {activeStep !== 0 && (
        <CpButton icon="arrow-line-left" btnType="flat" onClick={back}>
          Back
        </CpButton>
      )}
      <CpButton btnType="primary" disabled={!isValid || sending} showLoader={sending} onClick={next}>
        {sendInvitationStep === activeStep ? "Send invitation" : "Next step"}
      </CpButton>
    </div>
  );
}

const styles = StyleSheet.create({
  footer: {
    padding: "16px 24px",
    display: "flex",
    borderRadius: "0 0 1px 1px",
    justifyContent: "flex-end",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "var(--cps-color-silver)",
  },
  withBackButton: {
    justifyContent: "space-between",
  },
});
