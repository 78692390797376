import React from "react";
import { createRoot } from "react-dom/client";
import InviteTeamMemberWrapper from "./invite-team-member.wrapper.js";

export function inviteTeamMember({ closeCallback, clientId } = {}) {
  const container = document.createElement("div");
  container.setAttribute("id", "invite-team-member");
  document.body.appendChild(container);
  const root = createRoot(container);

  root.render(
    <InviteTeamMemberWrapper
      clientId={clientId}
      close={(finished) => {
        root.unmount();
        document.body.removeChild(container);
        if (finished === true) {
          closeCallback && closeCallback();
        }
      }}
    />
  );
}
