import React from "react";
import { StyleSheet, css } from "aphrodite/no-important";

export default class Header extends React.Component {
  render() {
    return (
      <div className={`${css(styles.steps)}`}>
        {this.props.steps.map((step, index) => {
          return (
            <div
              className={`${css(styles.step)}`}
              data-testid={`header-step-${index + 1}`}
              style={{
                fontWeight: this.props.activeStep === index ? "bold" : "normal",
              }}
              key={step}
            >
              <div className={`${css(styles.circle)}`}>
                {this.props.activeStep > index ? (
                  <span className={`cps-icon cps-icon-lg-check`} data-testid={`header-step-${index + 1}-checkmark`} />
                ) : (
                  index + 1
                )}
              </div>
              <div className={`${css(styles.stepText)}`}>{step}</div>
            </div>
          );
        })}
        <span onClick={this.props.close} className={`${css(styles.close)} cps-icon cps-icon-close`} />
      </div>
    );
  }
}

export const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  steps: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1px 1px 0 0",
    background: "#3D464D",
    position: "relative",
    height: "64px",
  },
  step: {
    display: "flex",
    alignItems: "center",
    marginRight: "37px",
  },
  circle: {
    fontWeight: "bold",
    backgroundColor: "#fff",
    color: "#3D464D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "24px",
    height: "24px",
    borderRadius: "12px",
    marginRight: "16px",
  },
  stepText: {
    fontSize: "16px",
    color: "#fff",
  },
  close: {
    color: "#fff",
    position: "absolute",
    right: "24px",
    cursor: "pointer",
    top: "16px",
  },
});
