import { fetchAsObservable } from "fetcher!sofe";
import canopyUrls from "canopy-urls!sofe";

const headers =
  typeof Headers !== "undefined"
    ? new Headers({
        "Content-Type": "application/json",
      })
    : {};

export function sendInvite(email, message, licenses, roleTemplateId, clientId) {
  const notifications = {
    message: message || "",
    url: clientId ? `${canopyUrls.getWebUrl()}/#/client/${clientId}` : `${canopyUrls.getWebUrl()}`,
  };
  if (!clientId) {
    notifications.template = "user-registration";
  }
  const newUser = {
    users: {
      email: email,
      clients: clientId ? [clientId] : [],
      role: "TeamMember",
      roleTemplateId: roleTemplateId,
      licenses: licenses,
      permissions: {},
    },
    notifications,
  };

  return fetchAsObservable(`/wg/users`, {
    headers: headers,
    method: "POST",
    body: JSON.stringify(newUser),
  });
}
