import React from "react";
import { StyleSheet, css } from "aphrodite";

export default class StepHeader extends React.Component {
  render() {
    return (
      <>
        <div className={`${css(styles.container)}`}>
          <img className={`${css(styles.img)}`} src={this.props.imgUrl} />
          <div>
            <div className={`cps-subheader${this.props.bigHeader ? "" : "-sm"}`}>{this.props.title}</div>
            <div>{this.props.description}</div>
          </div>
        </div>
        <hr className={`${css(styles.hr)}`} />
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    padding: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    height: "58px",
    marginRight: "24px",
  },
  hr: {
    margin: `0 24px`,
    width: `calc(100% - 48px)`,
  },
});
