import { useEffect, useState } from "react";
import { fetchAsObservable } from "fetcher!sofe";
import { warningToast } from "toast-service!sofe";
import { handleError } from "./error";

export function useRoleTemplates() {
  const [loading, setLoading] = useState(true);
  const [roleTemplates, setRoleTemplates] = useState([]);
  const permissionSetName = "permission set";

  useEffect(() => {
    let obs = fetchAsObservable(`/wg/role-templates`).subscribe(
      (response) => {
        setLoading(false);
        setRoleTemplates(response["role-templates"]);
      },
      (error) => {
        warningToast(`Failed to load ${permissionSetName} templates.`);
        handleError(error);
      }
    );

    return () => {
      obs.unsubscribe();
    };
  }, []);

  return {
    loading,
    roleTemplates,
  };
}
