import React from "react";
import Header from "./header.component.js";
import StepOne from "./step-one.component.js";
import StepTwo from "./step-two.component.js";
import StepThree from "./step-three.component.js";
import StepFour from "./step-four.component.js";
import Footer from "./footer.component.js";
import * as sendInviteResource from "./send-invite.resource.js";
import { successToast, warningToast } from "toast-service!sofe";
import { css, StyleSheet } from "aphrodite";
import emailValidator from "email-validator";
import { camelCase, partial } from "lodash";
import { handleError } from "./error.js";
import { CpModal } from "canopy-styleguide!sofe";
import Auth from "cp-client-auth!sofe";

const defaultState = {
  forceValidate: true,
  csCheckboxChecked: false,
  activeStep: 0,
  email: "",
  invalidEmail: false,
  message: "",
  isValid: true,
  isCanopyEmail: false,
  finished: false,
  selectedLicenses: [],
  verifyingEmail: false,
  isImplementationAtCanopyTaxEmail: false,
  roleTemplateId: null,
};

export default class InviteTeamMemberWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.steps = ["Prepare Invitation", "Assign Licenses", `Assign permission set`];
    this.state = defaultState;
  }
  componentDidMount() {
    // Ensure we have an accurate license count on initial load
    Auth.refetchTenant();
  }
  render() {
    return (
      <CpModal show width={700}>
        <Header
          steps={this.steps}
          close={partial(this.props.close, this.state.finished)}
          activeStep={this.state.activeStep}
        />
        <div className={`${css(styles.scrollableArea)}`}>{this.currentStep()}</div>
        {this.state.activeStep !== 3 && (
          <Footer
            sending={this.state.sending}
            activeStep={this.state.activeStep}
            isValid={this.validateStep()}
            next={this.next.bind(this)}
            back={this.back.bind(this)}
            close={partial(this.props.close, this.state.finished)}
          />
        )}
      </CpModal>
    );
  }
  toggleForceValidate = (bool) => {
    this.setState({
      forceValidate: bool,
    });
  };
  currentStep() {
    if (this.state.activeStep === 0)
      return (
        <StepOne
          toggleForceValidate={this.toggleForceValidate}
          isValid={this.state.isValid}
          email={this.state.email}
          message={this.state.message}
          updateEmail={this.updateEmail}
          updateMessage={this.updateMessage}
          setVerifyingEmail={this.setVerifyingEmail}
          setInvalidEmail={(val) => this.setState({ invalidEmail: val })}
          isCanopyEmail={this.state.isCanopyEmail}
          updateCheckboxForParent={this.updateCheckboxInChild}
        />
      );

    if (this.state.activeStep === 1 && !this.state.csCheckboxChecked)
      return (
        <StepTwo
          close={partial(this.props.close, false)}
          isValid={this.state.isValid}
          email={this.state.email}
          selectedLicenses={this.state.selectedLicenses}
          toggleLicense={this.toggleLicense}
          isCanopyEmail={this.state.isCanopyEmail}
        />
      );
    if (this.state.activeStep === 2 && !this.state.csCheckboxChecked) return <StepThree setRole={this.setRole} />;
    if (this.state.activeStep === 3)
      return <StepFour close={partial(this.props.close, this.state.finished)} restart={this.restart.bind(this)} />;
  }

  updateCheckboxInChild = (bool) => {
    this.setState({
      csCheckboxChecked: bool,
      selectedLicenses: bool ? ["canopyCustomerSuccess"] : [],
    });
  };

  next() {
    if (this.validateStep()) {
      if (this.state.activeStep === 2 || this.state.csCheckboxChecked) {
        this.setState({
          sending: true,
          activeStep: this.state.csCheckboxChecked ? this.state.activeStep + 2 : this.state.activeStep,
        });

        let { email, message, selectedLicenses, isCanopyEmail } = this.state;
        const roleTemplateId = this.state.roleTemplateId || (isCanopyEmail ? "__admin__" : null);
        sendInviteResource
          .sendInvite(email, message, selectedLicenses.map(camelCase), roleTemplateId, this.props.clientId)
          .subscribe(
            () => {
              successToast(`Invite sent to ${email}`);
              this.setState({
                finished: true,
                activeStep: this.state.activeStep + 1,
                isValid: true,
                sending: false,
              });
            },
            (err) => {
              warningToast(`The invite to ${email} has failed. Try again or contact support.`);
              handleError(err);
              this.setState({
                sending: false,
                error: true,
              });
            }
          );
      } else {
        this.setState({
          activeStep: this.state.activeStep + 1,
          isValid: true,
        });
      }
    } else {
      this.setState({
        isValid: false,
      });
    }
  }
  validateStep() {
    if (!this.state.forceValidate) {
      return false;
    }

    if (this.state.activeStep === 0) {
      if (!emailValidator.validate(this.state.email) || this.state.verifyingEmail || this.state.invalidEmail) {
        return false;
      }

      if (this.state.isImplementationAtCanopyTaxEmail && !this.state.csCheckboxChecked) {
        return false;
      }
    }

    if (this.state.activeStep === 2 && !this.state.roleTemplateId) {
      return false;
    }

    if (this.state.activeStep === 1 && this.state.selectedLicenses.length === 0) {
      return false;
    }

    return true;
  }
  back() {
    if (this.state.activeStep === 1) {
      this.setState({
        activeStep: this.state.activeStep - 1,
        selectedLicenses: [],
      });
    } else if (this.state.activeStep !== 0) {
      this.setState({
        activeStep: this.state.activeStep - 1,
        roleTemplateId: null,
      });
    } else {
      this.props.close(this.state.finished);
    }
  }
  restart() {
    this.setState(defaultState);
    // Update license count
    Auth.refetchTenant();
  }
  updateEmail = (inputEmail) => {
    const email = inputEmail.toLowerCase();
    this.setState({
      email,
      isCanopyEmail:
        email.replace(/\s/g, ``).split("@")[1] === "canopytax.com" ||
        email.replace(/\s/g, ``).split("@")[1] === "getcanopy.com",
      isImplementationAtCanopyTaxEmail: email.replace(/\s/g, ``) === "implementation@canopytax.com",
    });
  };
  updateMessage = (message) => {
    this.setState({ message });
  };
  toggleLicense = (license) => {
    this.setState((prevState) => {
      if (prevState.selectedLicenses.includes(license)) {
        return {
          selectedLicenses: prevState.selectedLicenses.filter((selectedLicense) => selectedLicense !== license),
        };
      } else {
        return { selectedLicenses: prevState.selectedLicenses.concat(license) };
      }
    });
  };
  setRole = (roleTemplateId) => {
    this.setState({
      roleTemplateId,
    });
  };
  setVerifyingEmail = (verifyingEmail) => {
    this.setState({ verifyingEmail });
  };
}

const styles = StyleSheet.create({
  scrollableArea: {
    overflowY: "auto",
    maxHeight: "480px", // about 3.5 license slats
  },
});
