import React, { lazy, Suspense } from "react";
import { CpLoader } from "canopy-styleguide!sofe";

const NotificationMessageImport = lazy(async () => {
  const { loadNotificationMessage } = await SystemJS.import("notifications-ui!sofe");
  return await loadNotificationMessage();
});

export default function NotificationMessage(props) {
  return (
    <Suspense fallback={<CpLoader />}>
      <NotificationMessageImport {...props} />
    </Suspense>
  );
}
