import React from "react";
import { StyleSheet, css } from "aphrodite";
import StepHeader from "./step-header.component.js";
import { fromEvent, throwError, of } from "rxjs";
import { pluck, distinctUntilChanged, debounceTime, switchMap, map, filter, tap, catchError } from "rxjs/operators";
import Cancelable from "react-disposable-decorator";
import emailValidator from "email-validator";
import { checkEmailAvailable } from "./available-emails.resource.js";
import { UserTenantProps } from "cp-client-auth!sofe";
import NotificationMessage from "./notification-message.component.js";
import { handleError } from "./error";

@UserTenantProps()
export class StepOne extends React.Component {
  static defaultProps = {
    rxjsScheduler: undefined,
  };
  constructor(props) {
    super();
    this.state = {
      customMessage: !!props.message,
      unavailableEmail: false,
      invalidEmail: false,
      isChecked: false,
    };
  }
  inputRef = React.createRef();
  componentDidMount() {
    /* We want to validate that the email they type in is (1) a valid email address, and
     * (2) not already taken by a different canopy. Only if all of that is true do we tell the
     * parent component about the email address, which enables the Next button so they can proceed.
     * We don't want to make a network request for every character they type, so we debounce the value
     * in the input. Additionally we do error handling if the email is already taken by a different user.
     */

    this.props.cancelWhenUnmounted(
      fromEvent(this.inputRef.current, "input")
        .pipe(
          pluck("target", "value"),
          tap(() => {
            this.setState(
              () => {
                // if they change the input, don't show the error message about the email being taken
                this.props.setInvalidEmail(false);
                return {
                  unavailableEmail: false,
                  invalidEmail: false,
                };
              },
              () => {
                this.props.setVerifyingEmail(true);
              }
            );
          }),
          debounceTime(300, this.props.rxjsScheduler),
          distinctUntilChanged(),
          filter((email) => {
            const isValidEmail = emailValidator.validate(email);
            if (isValidEmail) {
              return true;
            } else {
              this.props.updateEmail(email);
              return false;
            }
          }),
          switchMap((email) =>
            checkEmailAvailable(email).pipe(
              filter((result) => {
                if (result.available) {
                  this.props.updateEmail(email);
                  this.props.toggleForceValidate(true);
                  return true;
                } else {
                  throw result;
                }
              }),
              map(() => email),
              this.catchErrHelper(email)
            )
          ),
          tap(() => this.props.setVerifyingEmail(false))
        )
        .subscribe((email) => this.props.updateEmail(email), handleError)
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isCanopyEmail && this.props.isCanopyEmail && !this.state.isChecked) {
      this.toggleCheckbox();
    } else if (prevProps.isCanopyEmail && !this.props.isCanopyEmail && this.state.isChecked) {
      this.toggleCheckbox();
    }
  }

  catchErrHelper = (email) => {
    return catchError((err) => {
      if (err && err.available === false && email.split("@")[1] === "canopytax.com") {
        this.setState({
          unavailableEmail: err.sameTenant ? "inside" : "outside",
        });
        this.props.toggleForceValidate(false);
        return of(email);
      }
      if (err && err.available === false) {
        this.setState({
          unavailableEmail: err.sameTenant ? "inside" : "outside",
        });
        const resetParentEmail = "";
        return of(resetParentEmail);
      } else if (err.status === 400) {
        this.props.setInvalidEmail(true);
        this.setState({ invalidEmail: true });
        return of(email);
      } else {
        return throwError(err);
      }
    });
  };

  toggleCheckbox = () => {
    const { tenant } = this.props;
    // hide implementation invites for internal tenants and dont allow it to be checked
    if (tenant.is_internal) return;
    const splitEmail = this.props.email.split("@");
    const emailWithTenantId = `${splitEmail[0]}+${tenant.id}@${splitEmail[1]}`;
    this.setState(
      (prevState) => ({ isChecked: !prevState.isChecked }),
      () => {
        this.props.updateCheckboxForParent(this.state.isChecked);
        if (this.state.isChecked) {
          this.checkEmailAvailability(emailWithTenantId);
        } else {
          this.checkEmailAvailability(this.props.email);
        }
      }
    );
  };

  checkEmailAvailability = (email) => {
    this.props.cancelWhenUnmounted(
      checkEmailAvailable(email)
        .pipe(
          filter((result) => {
            if (result.available) {
              this.setState({ unavailableEmail: false });
              this.props.toggleForceValidate(true);
              return true;
            } else {
              this.setState({ unavailableEmail: true });
              this.props.toggleForceValidate(false);
              throw result;
            }
          }),
          this.catchErrHelper(email)
        )
        .subscribe(() => {}, handleError)
    );
  };

  render() {
    return (
      <div>
        <StepHeader
          title={"Invite a new team member"}
          bigHeader={true}
          description={"Invite a team member by sending them an invitation email"}
          imgUrl={"https://cdn.canopytax.com/static/licenses/team-members.svg"}
        />
        <div className={`${css(styles.body)}`}>
          <div
            className={`cps-form-group ${
              !this.props.isValid || this.state.unavailableEmail || this.state.invalidEmail ? "cps-has-error" : ""
            }`}
          >
            <label className={`cps-body ${css(styles.inputLabel)}`}>
              What&#39;s your new team member&#39;s email address?
            </label>
            <input
              autoFocus
              ref={this.inputRef}
              defaultValue={this.props.email}
              className={`cps-form-control`}
              placeholder={"Email address"}
            />
            {this.state.unavailableEmail && (
              <span className="cps-error-block">
                This email address is already in use by someone {this.state.unavailableEmail} of your practice.
              </span>
            )}
            {(!this.props.isValid || this.state.invalidEmail) && !this.state.unavailableEmail && (
              <span className="cps-error-block">Please provide a valid email address.</span>
            )}
            {this.props.isCanopyEmail && !this.props.tenant.is_internal && (
              <label className="cps-checkbox cps-margin-right-16">
                <input
                  type="checkbox"
                  aria-label="Invite Canopy employee to access your account"
                  checked={this.state.isChecked}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.toggleCheckbox();
                  }}
                />
                <span className={`${css(styles.checkbox)} cps-margin-top-16`}>
                  Looks like this is a Canopy employee, would you like to invite them to access your account?
                </span>
              </label>
            )}
          </div>
          <NotificationMessage
            setupFunction={this.handleNewNotificationMessage}
            defaultMessage={this.props.message}
            includeMessage={this.state.customMessage}
          />
        </div>
      </div>
    );
  }
  componentWillUnmount() {
    if (this.notificationMessage) {
      if (this.notificationMessage.includeMessage) {
        this.props.updateMessage(this.notificationMessage.message);
      } else {
        this.props.updateMessage("");
      }
    }
  }
  updateMessage = (e) => {
    this.props.updateMessage(e.target.value);
  };
  handleNewNotificationMessage = (notificationMessage) => {
    this.notificationMessage = notificationMessage;
  };
}

export default Cancelable(StepOne);

const styles = StyleSheet.create({
  body: {
    padding: "24px",
  },
  welcomeCheck: {
    padding: "8px",
    display: "flex",
    alignItems: "center",
  },
  inputLabel: {
    color: `var(--cps-color-cool-gray)`,
  },
  checkbox: {
    display: "inline-block",
    color: `var(--cps-color-cool-gray)`,
  },
});
