import React from "react";
import { StyleSheet, css } from "aphrodite";
import { CpButton } from "canopy-styleguide!sofe";

export default function StepFour({ close, restart }) {
  return (
    <div className={`${css(styles.body)}`}>
      <div>
        <img className={`${css(styles.img)}`} src={`https://cdn.canopytax.com/static/licenses/thumb-up-outline.svg`} />
      </div>
      <div className={`cp-subheader-sm cp-wt-bold`}>Invite sent, you're all set!</div>
      <div className={`cp-mb-24`}>The person you just invited should receive an email shortly.</div>
      <div>
        <CpButton className="cp-mr-8" onClick={close} btnType="primary">
          Finish
        </CpButton>
        <CpButton onClick={restart} btnType="secondary">
          Add another
        </CpButton>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  body: {
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    height: "88px",
    marginBottom: "8px",
  },
});
